<template>
<page-section
    :title="$t('News')"
    :subtitle="$t('NewsDescription')"
>



  <v-sheet class="d-flex justify-center pa-16 pt-6" :color="wsLIGHTCARD">


    <div class="main_section">
      <v-row>
        <v-data-iterator :items="news">
          <template v-slot:default="{ items }">
            <v-col v-for="(item,i) in items" :key="i"
                   cols="12" xs="12" sm="12" md="6" lg="4" xl="4"
                   class="pa-3">
              <router-link style="text-decoration: none" :style="`color : ${wsDARKER}`" :to="localeLink('news/' + item.alias)">
                <v-sheet  class="wsRoundedHalf ma-6 pa-2" >
                  <v-img v-if="item.img" class="wsRoundedHalf" aspect-ratio="1.5"  :src="item.img" ></v-img>
                  <v-img v-else class="wsRoundedHalf" aspect-ratio="1.5"  src="@/assets/img/course_blank.jpg" ></v-img>
                </v-sheet>
                <h5 class="text-center font-weight-light">{{ item.title }}</h5>
              </router-link>
            </v-col>
          </template>
        </v-data-iterator>

      </v-row>
    </div>
  </v-sheet>
</page-section>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "HomeNews",
  data() {
    return {
      news : []
    }
  },
  methods : {
    ...mapActions('news', [
          'GET_NEWS_MAIN_PUBLIC'
        ]
    ),
  },
  async mounted(){

    let result = await this.GET_NEWS_MAIN_PUBLIC('westudy')
    this.news = result.news
    this.notify('loaded')

  }
}
</script>

<style lang="scss" scoped>
.main_section {
  width:1100px
}
</style>